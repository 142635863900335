<template>
  <b-modal
    visible
    scrollable
    centered
    size="lg"
    :title="'Создание нового Юр.лица'"
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div>
      <b-row>
        <b-col>
          <b-form-group
            label="Название юр.лица"
          >
            <b-form-input
              v-model="$v.legalName.$model"
              type="text"
              :state="validateState('legalName')"
              :placeholder="'Введите название юр.лица'"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Партнер">
            <b-form-select
              v-model="$v.partnerId.$model"
              value-field="id"
              text-field="name"
              :state="validateState('partnerId')"
              label="name"
              trim
              :options="optionsPartner"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Адрес"
          >
            <b-form-input
              v-model="$v.address.$model"
              type="text"
              :state="validateState('address')"
              :placeholder="'Введите адрес'"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно и должно быть не менее 5 символов*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="ИНН"
          >
            <b-form-input
              v-model="$v.inn.$model"
              type="number"
              :state="validateState('inn')"
              :placeholder="'Введите инн'"
              trim
              :formatter="formatInn"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно и должно быть не менее 10 цифр*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="КПП"
          >
            <b-form-input
              v-model="$v.kpp.$model"
              type="number"
              :state="validateState('kpp')"
              :placeholder="'Введите кпп'"
              trim
              :formatter="formatKpp"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно и должно быть не менее 9 цифр*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Лицензия"
          >
            <b-form-input
              v-model="$v.license.$model"
              type="text"
              :state="validateState('license')"
              :placeholder="'Введите лицензию'"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно и должно быть не менее 5 символов*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Активирован">
            <b-form-select
              v-model="isActive"
              :options="optionsActivate"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Тип договора">
            <b-form-select
              v-model="$v.contractType.$model"
              :state="validateState('contractType')"
              :options="optionsContract"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="contractType == 0"
      >
        <b-col>
          <b-form-group
            label="Процент выплат"
          >
            <b-form-input
              v-model="$v.remunerationPercentage.$model"
              type="number"
              :state="validateState('remunerationPercentage')"
              placeholder="Введите процент"
              max="100"
              trim
              :formatter="formatRemunerationPercentage"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно и должно быть не более 3 цифр*
            </b-form-invalid-feedback>
          </b-form-group>
          <b-alert
            v-if="remunerationPercentage > 100"
            show
            variant="danger"
          >
            Процент не должен превышать значения 100
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Шапка документа">
            <b-form-textarea
              id="textarea"
              v-model="$v.createOrderGuaranteeHeader.$model"
              :state="validateState('createOrderGuaranteeHeader')"
              placeholder="Введите текст..."
              rows="3"
              max-rows="6"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Тема письма">
            <b-form-input
              v-model="$v.createOrderGuaranteeEmailSubject.$model"
              :state="validateState('createOrderGuaranteeEmailSubject')"
              placeholder="Введите текст..."
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Email адреса получателей"
          >
            <b-button
              size="sm"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              class="mb-2"
              @click="addOrderGuaranteeEmailAddress"
            >
              Добавить email
            </b-button>

            <b-alert
              v-if="!createOrderGuaranteeEmailAddresses?.length && !validateState('createOrderGuaranteeEmailAddresses')"
              show
              variant="danger"
            >
              Данное поле обязательно*
            </b-alert>
            <div
              v-for="(item, idx) in createOrderGuaranteeEmailAddresses"
              :key="idx"
              class="d-flex items-center gap-3 mb-2"
            >
              <b-form-input
                v-model="createOrderGuaranteeEmailAddresses[idx]"
                type="email"
                placeholder="Введите email..."
              />
              <b-button
                size="sm"
                variant="danger"
                :type="$const.PRIMARY_BUTTON"
                class="w-full ml-2 mr-2"
                @click="deleteOrderGuaranteeEmailAddress(idx)"
              >
                <b-icon icon="x-square" />
              </b-button>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Шаблон письма клиенту">
            <b-form-file
              v-model="createOrderClientEmailTemplate"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Шаблон ГП на создание заказа">
            <b-form-file
              v-model="createOrderGuaranteeTemplate"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          @click="onClickCreateLegalPartner"
        >
          Создать
        </b-button>
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  maxLength,
  minLength, required,
} from 'vuelidate/lib/validators';
import Loading from '@/components/Loading';
import { showValidationErrorMessage } from '@/helpers/messages';

export default {
  name: 'AddLegalPartnerModal',
  components: {
    Loading,
  },
  mixins: [validationMixin],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      userData: {},
      rows: [],
      legalName: '',
      inn: '',
      kpp: '',
      address: '',
      license: '',
      isActive: null,
      partnerId: '',
      optionsPartner: [],
      optionsActivate: [
        { value: null, text: 'Выберите состояние', disabled: true },
        { value: true, text: 'Активирован' },
        { value: false, text: 'Деактивирован' },
      ],
      contractType: null,
      optionsContract: [
        { value: null, text: 'Выберите тип договора', disabled: true },
        { value: 0, text: 'Агентский' },
        { value: 1, text: 'Оказания услуг' },
      ],
      remunerationPercentage: null,
      createOrderGuaranteeEmailSubject: '',
      createOrderGuaranteeHeader: '',
      createOrderGuaranteeEmailAddresses: [],
      createOrderGuaranteeTemplate: null,
      createOrderClientEmailTemplate: null,
    };
  },
  validations() {
    return {
      legalName: { required },
      inn: {
        required,
        minLength: minLength(10),
      },
      contractType: {
        required,
      },
      remunerationPercentage: {
        // eslint-disable-next-line eqeqeq
        required: this.contractType == 0 ? required : () => true,
        maxLength: maxLength(3),
      },
      createOrderGuaranteeHeader: {
        required,
      },
      createOrderGuaranteeEmailSubject: {
        required,
      },
      createOrderGuaranteeEmailAddresses: {
        required,
        minLength: minLength(1),
      },
      kpp: {
        required,
        minLength: minLength(9),
      },
      partnerId: { required },
      address: {
        required,
        minLength: minLength(5),
      },
      license: {
        required,
        minLength: minLength(5),
      },
    };
  },
  computed: {
    partnerListStore() {
      return this.$store.state.Laboratories.partnerList;
    },
  },
  watch: {
    contractType() {
      // eslint-disable-next-line eqeqeq
      if (this.contractType != 0) {
        this.remunerationPercentage = null;
      }
    },
  },
  async created() {
    await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNERS, {});
    this.optionsPartner = this.partnerListStore.data;
  },
  methods: {
    async onClose() {
      this.$emit('input', false);
    },
    async onClickCreateLegalPartner() {
      if (!this.onCheckValidation()) return;
      const params = {
        legalName: this.legalName,
        partnerId: this.partnerId,
        address: this.address,
        inn: this.inn,
        kpp: this.kpp,
        license: this.license,
        isActive: this.isActive ?? false,
        remunerationPercentage: this.remunerationPercentage,
        contractType: this.contractType,
        createOrderGuaranteeEmailAddresses: this.createOrderGuaranteeEmailAddresses,
        createOrderGuaranteeHeader: this.createOrderGuaranteeHeader,
        createOrderGuaranteeEmailSubject: this.createOrderGuaranteeEmailSubject,
        createOrderGuaranteeTemplate: this.createOrderGuaranteeTemplate,
        createOrderClientEmailTemplate: this.createOrderClientEmailTemplate,

      };

      // eslint-disable-next-line eqeqeq
      if (params.contractType == 1) {
        delete params.remunerationPercentage;
      }

      const fd = Object.entries(params).reduce((formData, [key, value]) => {
        formData.append(key, value);
        return formData;
      }, new FormData());

      await this.$store.dispatch(this.$types.LABORATORY_LEGAL_PARTNER_CREATE, fd);
      await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, this.params);
      this.onClose();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    onCheckValidation() {
      this.$v.$touch();
      if (this.$v.$error) {
        console.log(this.$v.error);

        showValidationErrorMessage();
        return false;
      }

      return true;
    },
    formatInn(e) {
      return String(e).substring(0, 10);
    },
    formatRemunerationPercentage(e) {
      return String(e).substring(0, 3);
    },
    formatKpp(e) {
      return String(e).substring(0, 9);
    },
    addOrderGuaranteeEmailAddress() {
      if (!this.createOrderGuaranteeEmailAddresses) this.createOrderGuaranteeEmailAddresses = [];
      this.createOrderGuaranteeEmailAddresses.push('');
    },
    deleteOrderGuaranteeEmailAddress(index) {
      if (this.createOrderGuaranteeEmailAddresses && this.createOrderGuaranteeEmailAddresses.length > index) {
        this.createOrderGuaranteeEmailAddresses.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-table {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #F3F3F3;
      color: #6E88F3;
    }

    & + & {
      border-top: 1px solid #E0E0E0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #E0E0E0;
    }
  }
}

</style>
